import PropTypes from "prop-types";
import styled from "styled-components";
import { ColorPalette, Fonts, HyperLink, LinkTypes, Badge } from "yuka";

import { QUERY_KEYS, useFetch } from "api";
import { KYC_BADGE_MAPPING } from "utils/constants";

import OnboardingRequestInfo from "./OnboardingRequestInfo";
import KYCActions from "./KYCActions";
import { KYC_REVIEW, KYC_INFORMATION_UPDATED } from "./constants";

const StyledHeader = styled.div`
  display: flex;
  margin-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${ColorPalette.white15};
`;

const StyledHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledHeaderActions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const StyledHeaderFirstRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  > :first-child {
    margin-right: 4px;
  }
`;

const ClientProfileHeader = ({
  source,
  clientProfile,
  id,
  latestOnboardingRequest,
  representatives,
}) => {
  let userMetadata = JSON.parse(localStorage.getItem("userMetadata"));
  const { data: sourceData } = useFetch(
    QUERY_KEYS.SOURCES.list({
      "filter[search_id]": clientProfile.parent ? clientProfile.parent : [],
    }),
    { enabled: !!clientProfile.parent }
  );

  const parent = sourceData?.[0] || null;

  return (
    <>
      <Fonts.Caption2theme50>
        <HyperLink url="../" linkType={LinkTypes.LOCAL_LINK}>
          Clients
        </HyperLink>
        <span> / {source.name}</span>
      </Fonts.Caption2theme50>
      <StyledHeader>
        <StyledHeaderText>
          <StyledHeaderFirstRow>
            <Fonts.Headline1theme100>{source.name}</Fonts.Headline1theme100>
          </StyledHeaderFirstRow>
          <span>
            <Fonts.Caption2theme50>
              <Fonts.Caption2theme80>{clientProfile.sourceType}</Fonts.Caption2theme80>
              {" | Parent Organization: "}
              {parent ? (
                <HyperLink url={`/clients/${parent.apiId}/`} linkType={LinkTypes.LOCAL_LINK}>
                  {parent.name}
                </HyperLink>
              ) : (
                <Fonts.Caption2theme80>None</Fonts.Caption2theme80>
              )}
              {" | ZX Reps: "}
              <Fonts.Caption2theme80>
                {representatives.length > 0
                  ? representatives.map(rep => rep.name).join(", ")
                  : "None"}
              </Fonts.Caption2theme80>
            </Fonts.Caption2theme50>
          </span>
          <span>
            <Fonts.Caption2theme50>
              {"Phone: "}
              <Fonts.Caption2theme80>{clientProfile.phone || "--"}</Fonts.Caption2theme80>
              {" | Email: "}
              <Fonts.Caption2theme80>{clientProfile.email || "--"}</Fonts.Caption2theme80>
              {" | Address: "}
              <Fonts.Caption2theme80>{clientProfile.address || "--"}</Fonts.Caption2theme80>
            </Fonts.Caption2theme50>
          </span>
          <span>
            <Badge badgeStyle={KYC_BADGE_MAPPING[clientProfile.kycStatus]}>
              KYC {clientProfile.kycStatus}
            </Badge>
          </span>
        </StyledHeaderText>
        <StyledHeaderActions>
          <KYCActions clientProfile={clientProfile} />
          {userMetadata?.canRequestClientKyc &&
            ![KYC_REVIEW, KYC_INFORMATION_UPDATED].includes(clientProfile.kycStatus) && (
              <OnboardingRequestInfo
                clientProfile={clientProfile}
                id={id}
                latestOnboardingRequest={latestOnboardingRequest}
              />
            )}
        </StyledHeaderActions>
      </StyledHeader>
    </>
  );
};

ClientProfileHeader.propTypes = {
  source: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  clientProfile: PropTypes.shape({
    kycStatus: PropTypes.string,
    sourceType: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    parent: PropTypes.number,
    lastOnboardingRequestSentOn: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  latestOnboardingRequest: PropTypes.shape({
    id: PropTypes.string,
    createdOn: PropTypes.string,
  }),
  representatives: PropTypes.arrayOf(
    PropTypes.shape({
      apiId: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default ClientProfileHeader;
